import './index.css';
import { useState, useEffect } from 'react';
import MyLoader from '../Loader';
import AnimatedHeading from '../AnimatedHeading';
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry";
import ImagesData from './images.jsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faXmark } from '@fortawesome/free-solid-svg-icons';


const Gallery = () => {

    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(true)
            setTimeout(() => {
                setLoading(false)
            },800)
    },[])

    const [letterClass, setLetterClass] = useState('text-animate')
    const nameArray = ['G','a','l','l','e','r','y',' ','|',' ','S','o','m','e',' ','M','e','m','o','r','i','e','s']
    
    
    function HoverAnimation () {
        return setTimeout(() => {
            setLetterClass('text-animate-hover')
          }, 4000)
    }
    useEffect(() => {
        HoverAnimation();
      }, [])
    
      const [imgData, setimgData] = useState({img: '', id: 0})
    const viewImage =(id,img) => {
        setimgData({img,id})
    }
    const imgAction = (action) => {
        let id = imgData.id;
        if(!action){
             setimgData({img:'', id:0})  
        }
    }
    // const [gallCollection,setgallCollection] = useState([])
    // setgallCollection([... new Set(ImagesData.map((item) => item.title ))])
   
    return(
<>
        {
            loading ? <MyLoader /> 
            :  
<>              {   imgData.img && (
                        <div style={{
                            zIndex:'99999',
                            width:"100%",
                            height:"100vh",
                            background: 'rgba(255, 255, 255, 0.162);',
                            backdropFilter:'blur(10px)',
                            top:'0',
                            position: 'fixed',
                            display: 'flex',
                            justifyContent:'center',
                            alignItems:'center',
                            overflow:'hidden',
                            
                        }}>
                            <FontAwesomeIcon icon={faXmark} 
                                  onClick={() => imgAction()}
                            style={{
                                position:'absolute',
                                top:'1%',
                                right:'2%',
                                padding:'0.9rem 1.1rem',
                                fontSize:'1.5rem',
                                cursor:'pointer',
                                backgroundColor:'#333',
                                borderRadius:'50%',
                                color:'#eee',
                                
                                }} />
                                {/* <FontAwesomeIcon 
                                onClick={() => imgAction('previous-img')}
                                style={{fontSize:'1.5rem',padding:'0.9rem 1.1rem', backgroundColor:'#333',color:'#eee',cursor:'pointer',borderTopLeftRadius:'2rem',borderBottomLeftRadius:'2rem'}} icon={faChevronLeft} /> */}
                            <img
                                src={imgData.img} style={{width: 'auto', maxWidth:'90%',maxHeight:'90%',borderRadius:'1rem',objectFit:'contain',}}
                            />
                                {/* <FontAwesomeIcon
                                    onClick={() => imgAction('next-img')}
                                style={{fontSize:'1.5rem',padding:'0.9rem 1.1rem', backgroundColor:'#333',color:'#eee',cursor:'pointer',borderTopRightRadius:'2rem',borderBottomRightRadius:'2rem'}} icon={faChevronRight} /> */}
                        </div>
)

}
              <div className='Headings GalleryHeading' id='main'>
                <h1><AnimatedHeading 
                    letterClass={letterClass}
                    strArray={nameArray}
                    idx={12}
                    /></h1>
                    <h4>HABS wants everybody to get connected with them <br/> through some mesmerizing pictures which they shared.
            </h4>
            </div>
    
            <div style={{padding:'1rem'}}>
            <ResponsiveMasonry
                columnsCountBreakPoints={{350: 1, 750: 2, 900: 3}}
            >
                <Masonry gutter='2px' 
                    className='Image_gallery'
                >
                    {ImagesData.map((e) => (
                        <img className='GalleryImg'
                            key={e.id}
                            src={e.imageLink}
                            style={{width: "100%", display: "block", borderRadius:"0.2rem",cursor:"pointer",transition:'scale 350ms ease,opacity 350ms ease'}}
                            alt=""
                            onClick={() => viewImage(e.id,e.imageLink)}
                        />
                    ))}
                </Masonry>
            </ResponsiveMasonry>
            </div>


</>
        }

</>
    );
}

export default Gallery;