import './index.css';
import {useState , useEffect} from 'react';
import MyLoader  from '../Loader';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AnimatedHeading from '../AnimatedHeading';
import PrgmData from './prgmData.jsx';




const Programs = () => {

    
const [letterClass, setLetterClass] = useState('text-animate')
const nameArray = ['O','u','r','  ','P','r','o','g','r','a','m','s']


function HoverAnimation () {
    return setTimeout(() => {
        setLetterClass('text-animate-hover')
      }, 4000)
}
useEffect(() => {
    HoverAnimation();
  }, [])

    
    const [loading, setLoading] = useState(false);
        useEffect(() => {
            setLoading(true)
                setTimeout(() => {
                    setLoading(false)
                },800)
        },[])

const [popopen, setPopopen] = useState(false);
const [popData, setpopData] = useState(PrgmData);

const handleFilter = (NeedID) => {
    const filtered = PrgmData.filter(data => data.id == NeedID);
    setpopData(filtered);
    setPopopen(!popopen)

  
  };

   return ( 
<>
 {
            
            
            loading ? <MyLoader /> 
            :
<>
        <div className='Headings' id='main'>
            <h1><AnimatedHeading 
                 letterClass={letterClass}
                 strArray={nameArray}
                 idx={12}
                /></h1>
            <h4>Here is the small glimpse of programs conducted by us !</h4>
        </div>

            <div className='PrgmWrapper'>
{
    PrgmData.map(({id, image, title, link, description}) => {
        return(
            
            <article key = {id} className="box">
                        <img 
                        src={image} 
                        alt={title} />

                <div className="infoBox">
            <div className="prgm-name">
                <h2>{title}</h2>
            </div>
        <div className='BtnCon'>
             <button className="action-btns" onClick={event => handleFilter(id)} data-toggle="PopInfoBox" data-target="#myModal" >Open</button>
             <a href={link} className="action-btns dark" target='_blank'>View</a>
         </div>
         </div>
    </article>

        )
    })
} </div>
</>
        
}


        {popopen && (
     <>        

<div className='PopBox'  onClick={handleFilter}>

    {
    popData.map(e => { 
        
        return(

            <div className='PopInfoBox' id='#myModal'>
                                <img src={e.image}  />
                                    <div className='PopDetail'>
                                        <h2>{e.title}</h2>
                                            <p> {e.description}</p>
                                        <div className="PopBtn">
                                             <a href={e.link} className="action-btn" target='_blank'>View More</a>
                                        </div>
                                    </div>
                                <FontAwesomeIcon icon={faXmark} className='Xmark' onClick={handleFilter} />
                        </div>
        );
                         })
    }
    </div>
    </>
        )}
   

      </>  
      );
}
export default Programs;


 