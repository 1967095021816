import './index.css';
import { useState, useEffect, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import AnimatedHeading from '../AnimatedHeading';
import { EffectCards } from 'swiper/modules';
import {motion} from 'framer-motion'
import promise from '../../assests/images/promise.jpg';
import jitendra from '../../assests/images/jitendrasir.jpg';

// swiper card css
import 'swiper/css';
import 'swiper/css/effect-cards';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';


const TestiData = [
    {
        id:1,
        name:'Jitendra Thapa',
        post:' Ward President, Itahari-9',
        link:'https://www.facebook.com/jitendra.thapa.587',
        image:jitendra,
        testimonial:`As a ward president, I want to express my heartfelt appreciation for the dedication of HABS members. They inspire us with their energy creativity and the boundless enthusiasm they bring to every endeavor. I've seen HABS members organizing awareness collaborative programs, and even educational workshops for their peers. They have shown us that age is no barrier to making a difference.`
    },
    {
        id:2,
        name:'Surendra Bhandari',
        post:'President of Lakshyadeep',
        link:'https://www.facebook.com/promise.bhandari.520',
        image:promise,
        testimonial:`"Hamro Aawaj Baal Sanjal" serves as a reminder of the love, respect and attention that children need. This exceptional club's committed members promote children's rights both locally and internationally, paving the road for Itahari to become child-friendly. Their firm dedication is inspirational.`
    },
    // {
    //     id:3,
    //     name:'Surendra Bhandari',
    //     post:'President of Lakshyadeep',
    //     link:'',
    //     image:promise,
    //     testimonial:`"Hamro Aawaj Bal Sanjal serves as a reminder of the love, respect and attention that children need. This exceptional club's committed members promote children's rights both locally and internationally, paving the road for Itahari to become child-friendly. Their firm dedication is inspirational.`
    // },
    // {
    //     id:4,
    //     name:'Surendra Bhandari',
    //     post:'President of Lakshyadeep',
    //     link:'',
    //     image:promise,
    //     testimonial:`"Hamro Aawaj Bal Sanjal serves as a reminder of the love, respect and attention that children need. This exceptional club's committed members promote children's rights both locally and internationally, paving the road for Itahari to become child-friendly. Their firm dedication is inspirational.`
    // },
   
  
]
const Testimonial = () => {

    const [letterClass, setLetterClass] = useState('text-animate')
const nameArray = ['T','e','s','t','i','m','o','i','a','l','s']


function HoverAnimation () {
    return setTimeout(() => {
        setLetterClass('text-animate-hover')
      }, 4000)
}
useEffect(() => {
    HoverAnimation();
  }, [])


    return(
<>
     <div className='Headings TestiHeading' id='Testimonials'>
            <h1><AnimatedHeading 
                 letterClass={letterClass}
                 strArray={nameArray}
                 idx={12}
                /></h1>
        </div>

     <div className='TestiContainer'>

     <Swiper
        effect={'cards'}
        grabCursor={true}
        modules={[EffectCards]}
        className="TestiSwiper"
      >
        {
            TestiData.map((e) => {

                return (

                    <SwiperSlide style={{cursor:'pointer'}} key={e.id} className='MySwiperCards'>
                    <div className='TestiImage'>
                                <motion.img style={{cursor:'grab'}}
                            src={e.image}
                            alt='TestiImage'
                            />
                    </div>
                    <div className='TestiDetails'>
                            <div><h3>{e.post}</h3><h2>{e.name}</h2></div>
                            <p>{e.testimonial}</p>
                    </div>
                    <div className='TestiSocial'>
                        <a href={e.link} target='_blank'>Get connected to them : <FontAwesomeIcon className='IconBro' icon={faFacebook} /></a>
                    </div>
                    </SwiperSlide>

                )
            })
        }
       


      </Swiper>

     </div>

</>
    );
}


export default Testimonial;


