import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './index.css'
import { faArrowUp, faLocationDot, faPhone } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
    
    return(
       
            <footer className='FooterContainer'>

                    <div className='footTop'>
                        <h1>
                            <span className='First'><span >H</span>amro</span>
                            <span className='second'><span> A</span>waj</span>
                            <span className='third'><span > B</span>aal</span>
                            <span className='fourth'><span > S</span>anjal</span>
                        </h1>

                        <a href='#main'><div className='GoTop'>
                        <FontAwesomeIcon className='GoDot' icon={faArrowUp} />
                        </div></a>
                 </div>
                 <div className='footMiddle'>

                    <div className='FootInfo'>
                        <h3>About</h3>
                        <p>'Hamro Awaj Baal Sanjal(HABS)' is a ward-level Child Club Network of Itahari Sub-Metropolitian City, Ward-9,Sunsari Nepal. 
                            It was established on 28th Baishak, 2072 with a total of 9 members in the working committee. The main motto behind establishing
                            this network was to create a child-friendly environment (बालमैत्री समाज).
                        </p>
                    </div>
                    <div className='FootInfo addInfo'>
                        <h3>Address</h3>
                        <div className='addBox'>
                            <div className='addHolder'>
                            <FontAwesomeIcon className='FootIcon' icon={faLocationDot} /> <p>Itahari-9, Sunsari</p>
                            </div>
                            <div className='addHolder'>
                                <FontAwesomeIcon className='FootIcon' icon={faPhone} /> <p>9800000000</p>
                            </div>
                            <div className='addHolder'>
                           <a href='mailto:wccn.habs@gmail.com'> <FontAwesomeIcon className='FootIcon' icon={faEnvelope} /></a> <p>wccn.habs@gmail.com</p>
                            </div>
                        </div>
                    </div>

                    <div className='FootSocial'>
                    <h3>Social</h3>
                    <div className='addBox SocialBox'>
                            <div className='addHolder'>
                           <a href='mailto:wccn.habs@gmail.com'> <FontAwesomeIcon className='FootIcon SocialIcon' icon={faEnvelope} /></a>
                            </div>
                            <div className='addHolder'>
                           <a href='https://www.facebook.com/habs.itahari' target='_blank'> <FontAwesomeIcon className='FootIcon SocialIcon' icon={faFacebook} /></a>
                            </div>
                            <div className='addHolder'>
                           <a href='https://www.facebook.com/habs.itahari' target='_blank'> <FontAwesomeIcon className='FootIcon SocialIcon' icon={faFacebook} /></a>
                            </div>
                        </div>
                    </div>

                 </div>

                <div className='footDown'>
                       <div className='horiLine'></div>
                        <div className='FootText'>
                                <p>Hamro Awaj Baal Sanjal © 2023 - All Rights Reserved </p>
                                <p>Made by <a href='https://sakshamk.com.np/' target='_blank'><span>@</span>Saksham</a> & <a href='https://mgautam.com.np/' target='_blank'><span>@</span>Manjey</a></p>
                        </div>
                </div>

            </footer>

    );
}

export default Footer;
