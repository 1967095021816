import './index.css';
import AnimatedHeading from '../AnimatedHeading';
import { useRef , useState , useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelopeOpen, faPaperPlane } from '@fortawesome/free-regular-svg-icons';
import Lottie, {LottieRefCurrentProps} from 'lottie-react';
import ContactAnimationData from '../AnimatedSvgs/ContactSvg.json';
import emailjs from "@emailjs/browser";
import sentEmailData from '../AnimatedSvgs/sentEmail.json'
import { faXmark } from '@fortawesome/free-solid-svg-icons';



const Contact = () => {
    useEffect(() => emailjs.init("WKYG0LB9u2jkSoCC4"), []);

    const nameRef = useRef();
const emailRef = useRef();
const subjectRef = useRef();
const messageRef = useRef();


const [confirm,setconfirm] = useState(false)

const closeConfirm = () => {
    setconfirm(!confirm)
}

const [loading, setLoading] = useState(false);
const handleSubmit =  async (e) => {
    e.preventDefault();
    const serviceId = 'service_dq8hhy8';
    const templateId = 'template_79xvm3b';

    try {
        setLoading(true);
        await emailjs.send(serviceId, templateId, {
         name: nameRef.current.value,
          email: emailRef.current.value,
          subject: subjectRef.current.value,
          message: messageRef.current.value
        });

        {
            setconfirm(true)
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
      nameRef.current.value = ''
      emailRef.current.value = ''
      subjectRef.current.value = ''
      messageRef.current.value = ''
}

    const [letterClass, setLetterClass] = useState('text-animate')
const nameArray = ['C','o','n','t','a','c','t'," ",'U','s']


function HoverAnimation () {
    return setTimeout(() => {
        setLetterClass('text-animate-hover')
      }, 4000)
}
useEffect(() => {
    HoverAnimation();
  }, [])

  
  
    const ContactRef = useRef<LottieRefCurrentProps>(null)
    const sentEmailRef = useRef<LottieRefCurrentProps>(null)
    return(

       <>
<div className='Headings' id='ContactUs'>
            <h1><AnimatedHeading 
                 letterClass={letterClass}
                 strArray={nameArray}
                 idx={12}
                /></h1>
            <h4>Use the form below to let    us know you ! 
                <br />or can simply email us !<br />
                <a href='mailto:wccn.habs@gmail.com'><FontAwesomeIcon className='EmailIcon' icon={faEnvelopeOpen} /> &nbsp;wccn.habs@gmail.com</a>
             </h4>
        </div>

        <div className='contactPageMain'>
            
        <div className='contactImage'>
                        <Lottie 
                        lottieRef={ContactRef}  
                         animationData={ContactAnimationData} />
                </div>
            <div className='contactForm'>
               
                    <form onSubmit={handleSubmit}>
                    <h1>
                    Lets Get In Touch
                </h1>
                        <div className='FormItems'>
                            <input ref={nameRef} type='text' required placeholder='Name '/>
                        </div>
                        <div className='FormItems'>
                            <input ref={emailRef} type='email' required placeholder='Email '/>
                        </div>
                        <div className='FormItems'>
                           <input ref={subjectRef} type='text' required placeholder='Subject '/>
                        </div>
                        <div className='FormItems'>
                           <textarea ref={messageRef} required placeholder='Message ' rows={6} cols={20}></textarea>
                        </div>
                        <div className='FormItems FormButton'>
                            <button type='submit'>Send <FontAwesomeIcon className='SubmitPlane' icon={faPaperPlane} /></button>
                        </div>
                    </form>
            </div>

               
        </div>  


         {confirm && (

            <div className='confirmBox'>

            <div className='confirmSvg'>
            <Lottie 
            className='lottieSvg'
                    lottieRef={sentEmailRef}  
                     animationData={sentEmailData}
                     loop={false} />
            </div>
            <p>
                Thank you for messaging us ! We'll try to get back to you as soon as possible .
                <br /> <br />- With best regards HABS family.
            </p>
           <FontAwesomeIcon className='closeBtnForm' icon={faXmark} onClick={closeConfirm} />

        </div>
         )

         }

       </>
    );
}

export default Contact;