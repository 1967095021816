import './App.css';
import Home from './Components/Home';
import { Route, Routes } from 'react-router-dom';
import Layout from './Components/Layout';
import About from './Components/About';
import Contact from './Components/Contact';
import Testimonial from './Components/Testimonial';
import Gallery from './Components/Gallery'
import Tenures  from './Components/Tenures';
import Programs from './Components/Programs';

const App = () => {

  return (
   <>
      <Routes>

          <Route path='/' element={<Layout />} >
            
                <Route index element={<Home />} />
                {/* <Route path='aboutus' element={<About />} /> */}
                <Route path='programs' element={<Programs />} />
                {/* <Route path='contactus' element={<Contact />} /> */}
                <Route path='tenures' element={<Tenures />} />
                {/* <Route path='testimonial' element={<Testimonial />} /> */}
                <Route path='gallery' element={<Gallery />} />

            </Route>

      </Routes>
    
    </>
  );
}

export default App;
