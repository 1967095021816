import './index.css';
import punagathan from '../../assests/images/pungathan2080.jpeg'
import { useState, useEffect, useRef } from 'react';
import AnimatedHeading from '../AnimatedHeading';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesRight } from '@fortawesome/free-solid-svg-icons';


const About = () => {
    
    const [letterClass, setLetterClass] = useState('text-animate')
const nameArray = ['A','b','o','u','t'," ",'U','s']


function HoverAnimation () {
    return setTimeout(() => {
        setLetterClass('text-animate-hover')
      }, 4000)
}
useEffect(() => {
    HoverAnimation();
  }, [])

  
    return(
<>
<div className='Headings' id='AboutUs'>
            <h1><AnimatedHeading 
                 letterClass={letterClass}
                 strArray={nameArray}
                 idx={12}
                /></h1>
        </div>


        <div className='AboutContainer' >

                    <div className='aboutInfo'>
                            <div className='InfoUS'>
                            <h1 className='aboutH1'>
                                Know about HABS
                            </h1>
                            <div className='infoContainer'>
                                <p>
                                Hamro Awaj Baal Sanjal (Ward Level Child Club Network of Itahari-9 ,Sunsari) was established on 28th Baishakh 2072 (11th May, 2015) with a beautiful and effective slogan "हाम्रो आवाज, हाम्रो आधिकार" ("Our Voice, Our Right"). There were only 9 members during the time of establishment. The sole reason behind the formation of this Network was to create a child-friendly environment (बालमैत्री समाज). In present context its being lead by more than 22 members who are actively working to achieve the motto. Numerous programs are constantly being held in different occasion throughout the year lead by: Purnima Bhattarai as President, Shriyasha Thapa as Vice President, Jeshis Jamarkatel as Secretary, Shreyashi Bhattarai as Vice Secretary , and Prasiddhi Bhandari as Treasurer.
                                </p>
                            </div>
                            <div className='ButtonsAbout'>
                                <div className='aboutBtn'>
                                <Link to="/Tenures"  exact="true" className='aboutBtn1' >
                                   <a>Our Team</a>
                                    <FontAwesomeIcon icon={faAnglesRight}  className='aboutFontAw'/>
                                </Link>
                                </div>
                                <div className='aboutBtn'>
                                <a href='#ContactUs' className='aboutBtn1' >
                                   <a>Contact Us</a>
                                    <FontAwesomeIcon icon={faAnglesRight}  className='aboutFontAw'/>
                                </a>
                                </div>
                            </div>
                            </div>
                    </div>

                      <div className='aboutAnimation'>

                                <img 
                                src={punagathan}
                                />
                    </div>  
        </div>
</>
    );
}

export default About;
