import './index.css';
import { useState, useEffect, Fragment } from 'react';
import MyLoader from '../Loader';
import AnimatedHeading from '../AnimatedHeading';
import saksham from '../../assests/images/saksham.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faLinkedin, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faCode } from '@fortawesome/free-solid-svg-icons';
import TenureData from './tenureData.jsx';


const Tenures = () => {
    
    const FirstTenureData = TenureData.filter(data => data.tenure === 'FirstTenure');
    const SecondTenureData = TenureData.filter(data => data.tenure === 'SecondTenure');
    const ThirdTenureData = TenureData.filter(data => data.tenure === 'ThirdTenure');
    const FourthTenureData = TenureData.filter(data => data.tenure === 'FourthTenure');
    
    const [showtenureData ,setshowtenureData] = useState(FourthTenureData);
    const [active, setActive] = useState("4");

    const forFirstTenure = () => {
        setActive('1')
        setshowtenureData(FirstTenureData)
    }
        const forSecondTenure = () => {
        setActive('2')
        setshowtenureData(SecondTenureData)
        }
        const forThirdTenure = () => {
        setActive('3')
        setshowtenureData(ThirdTenureData)
        }
        const forFourthTenure = () => {
        setActive('4')
        setshowtenureData(FourthTenureData)
        }

    const [letterClass, setLetterClass] = useState('text-animate')
    const nameArray = ['M','e','e','t',' ','O','u','r',' ','T','e','a','m']
    
    
    function HoverAnimation () {
        return setTimeout(() => {
            setLetterClass('text-animate-hover')
          }, 4000)
    }
    useEffect(() => {
        HoverAnimation();
      }, [])

    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(true)
            setTimeout(() => {
                setLoading(false)
            },800)
    },[])

    return(
        
<>
        {
            loading ? <MyLoader /> 
            : 
    <>
                <div className='Headings' id='main'>
            <h1><AnimatedHeading 
                 letterClass={letterClass}
                 strArray={nameArray}
                 idx={12}
                /></h1>
            <h4>Lets get introduced to our team members and major five <br /> from previous tenures as well .
            <br /> Simply use the options below to select particulat Tenure
            </h4>
        </div>



          <div className='TenureWrapper'>
            
        <div className='filterWrapper'>
           <div className= {active === '1' ? 'activeBtn' : 'filterBtn'}  role='button' onClick={forFirstTenure}>FirstTenure </div>
           <div className={active === '2' ? 'activeBtn' : 'filterBtn'}  role='button' onClick={forSecondTenure}>SecondTenure </div>
           <div className={active === '3' ? 'activeBtn' : 'filterBtn'}  role='button' onClick={forThirdTenure}>ThirdTenure </div>
           <div className={active === '4' ? 'activeBtn' : 'filterBtn'}  role='button' onClick={forFourthTenure}>FourthTenure </div>
        </div>
                  


{
    showtenureData.map((e) => {
        return(
            <div className='TeamCardWrapper' key={e.id}>
                    <div className='TeamCard'>
                        <div className='TeamCardFront'>
                            <img 
                                src={e.image}
                                alt={e.name}
                            />
                        </div>  
                        <div className='TeamCardBack'>
                            <img
                            src={e.image}
                            />
                           <div className='information'>
                            <h2>{e.name}</h2>
                                <h3><b>{e.post}</b> ({e.date})</h3>
                                <h3>{e.tenure}</h3>
                           </div>
                            <div className='links'>
                                <a href={e.fblink}><FontAwesomeIcon className='Tlinks' icon={faFacebook} /></a>
                                <a href={e.iglink}><FontAwesomeIcon className='Tlinks' icon={faInstagram} /></a>
                                <a href={e.linkedin}><FontAwesomeIcon className='Tlinks' icon={faLinkedin} /></a>
                               <a href={e.ytlink}> <FontAwesomeIcon className='Tlinks' icon={faYoutube} /></a>
                                <a href={e.website}><FontAwesomeIcon className='Tlinks' icon={faCode} /></a>
                            </div>
                        </div>
                    </div>
                </div>

        );
    })
}
          


          </div>


            
     </>
        }


</>
    );
}

export default Tenures;