import BaalVela from '../../assests/images/baalvela.jpg';
import Anni from '../../assests/images/anni7.jpg';
import Sport from '../../assests/images/sportsEvent.jpg';
import Dashain2079 from '../../assests/images/dashian.jpg';
import Deusi from '../../assests/images/deusi2079.jpg';
import Puna2078 from '../../assests/images/punaghathan.jpg';
import Chunab from '../../assests/images/chunab.jpg';
import Xyamata from '../../assests/images/xyamata.jpg';
import quiz from '../../assests/images/quiz.jpg';
import punaghathan from '../../assests/images/pungathan2080.jpeg';
import tihar2080 from '../../assests/images/tihar2090.jpg'
import dashain2080 from '../../assests/images/dashain2080.jpg';
import baalJana from '../../assests/images/balJana.jpg';

const PrgmData = [
    {
        id:9,
        image:quiz,
        title:'हाजिरीजवाफ प्रतियोगिता',
        link:'https://www.facebook.com/story.php?story_fbid=pfbid02WUXZE5SwV9QPNV8foKnad1kThxr5rAWnvytPqJTj3Wx3MBpZbzEpM671wPW6YfGSl&id=100063607870974&mibextid=Nif5oz&paipv=0&eav=Afb3wX7ZkAsnPNJOdCvOEO957yUyMA-gmnoH9l0autqekbgUXE1owFGW2sSu-FjYtBg&_rdr',
        description:'५९ औं राष्ट्रिय बाल दिवसको विशेष अवसरमा ९ नं. वडा बाल सञ्जाल इटहरी (हाम्रो आवाज बाल सञ्जाल) द्वारा मिति २०८०/०५/२९ गतेका दिन लक्ष्यदीपको सह-आयोजना र ९ नं वडा कार्यालय इटहरीको सहकार्यमा "हाजिरीजवाफ प्रतियोगिता" कार्यक्रम सम्पन्न गरेको छ।',
    },
    {
        id:1,
        image:Dashain2079,
        title:"दशैं: शुभकामना आदानप्रदान कार्यक्रम २०७९",
        link:"https://www.facebook.com/habs.itahari/posts/pfbid033E3EpGQcHuJTeTyFxasF2v57mPoHMKm86nPzsWeiyNUd7M1EiVpz9ND8XJcfEx7l",
        description:"मिति २०७९/०६/२२ गतेका दिन हाम्रो आवाज बाल सञ्जाल तथा बाल क्लब पूर्व सदस्यहरूको आयोजना तथा इटहरी ९ नं. वडा अध्यक्ष जितेन्द्र थापाको प्रमुख आतिथ्यतामा भ्वाइस अफ चिल्ड्रेन सुनसरी (VOC) परिवारसँग वडा दशैं विशेष शुभकामना आदानप्रदान कार्यक्रम सफलतापूर्वक सम्पन्न गरियो।"
    },
    {
        id:2,
        image:Deusi,
        title:"देउसी-भैलो कार्यक्रम २०७९",
        link:"https://www.facebook.com/habs.itahari/posts/pfbid0r7G19sVS9psr8sdfrT8iBPQvTBPCjyJuisJBo7zmrfA5QkAYDRLFDKqDEy2DyfSvl",
        description:"२०७९ सालको तिहार विशेष देउसी-भैलो कार्यक्रमका केही झलकहरु।"
    },
    {
        id:3,
        image:Puna2078,
        title:"दोस्रो पुन: गठन कार्यक्रम २०७८ ",
        link:"https://www.facebook.com/habs.itahari/posts/pfbid02uAQ22cQpNnpUd2zVTNDWxG5CpP79f4nqDQmR1dk5xA9LfcByMxDFphneHf5kuHa9l",
        description:` मिति २०७८/०७/०६ गतेका दिन 'हाम्रो आवाज बाल सञ्जाल' (९ नं. वडा बाल सञ्जाल, इटहरी) को दोस्रो पुन गठन कार्यक्रम इटहरी उप-महानगरपालिका ९ नं. वडा कार्यालयको नेतृत्वमा सम्पन्न गरिएको छ।`

    },
    {
        id:10,
        image:punaghathan,
        title:'तेस्रो पुन: गठन कार्यक्रम २०८०',
        link:'https://www.facebook.com/story.php?story_fbid=pfbid074B2okbPhSy9dUu9HiDPdxUvGjTq18yvUkRTFxH8Pq3TvgqMk32fJsYajvmKdRNol&id=100063607870974&mibextid=Nif5oz&paipv=0&eav=AfYkkZ7cMJQoruhbiiTOvAqVWxp26wMVWNtedrtyNvi0S7Zu-rzG0pgNQmVK-_4KcSA&_rdr',
        description:'मिति २०८०/०९/०७ गतेका दिन ९ नं. वडा बाल सञ्जाल, इटहरी (Hamro Awaj Baal Sanjal) को तेस्रो पुन: गठन कार्यक्रम इटहरी उप-महानगरपालिका ९ नं. वडा कार्यालयको नेतृत्वमा सम्पन्न गरिएको छ।',
    },
    {
        id:13,
        image:baalJana,
        title:'बालबालिकासँग जनप्रतिनिधि',
        link:'https://www.facebook.com/story.php?story_fbid=pfbid0mPuonBRegQ34dXsopS63D4fuptTLTffy3RWG5pounxw2zRd4cJaGJfW9ZSzZoWP5l&id=100063607870974&mibextid=Nif5oz&paipv=0&eav=AfZpORYlBbhB0pqeujjP1T1Y6fmV8eX7zdoX6yTXcZkAu5udovggLwejopX9Sj09yDg&_rdr',
        description:`५८ औँ राष्ट्रिय बाल दिवसको विशेष अवसरमा मिति २०७९/०५/२४ गतेका दिन हाम्रो आवाज बाल सञ्जालद्वारा इटहरी उप-महानगरपालिकाका प्रमुख Hemkarna Poudel  र ९ नं. वडा अध्यक्ष जितेन्द्र थापाको गरिमामय उपस्थितिमा 'बालबालिकासँग जनप्रतिनिधि' नामक अन्तरक्रिया कार्यक्रम सफलतापूर्वक सप्पन्न गरिएको छ। उक्त कार्यक्रममा इटहरी ९ नं. वडाका विभिन्न बाल सञ्जाल, टोल तथा इटहरीका विभिन्न विद्यालयबाट जम्मा १०९ जना बालबालिकाको उपस्थिति रहेको थियो। नगर प्रमुख साथै ९ नं. वडा अध्यक्षले बालबालिकाका जिज्ञासा सम्बोधन गरि बालमैत्री वडा/नगर निर्माणका लागि प्रतिबद्धता समेत जनाउनु भएको छ।`,
    },
    {
        id:4,
        image:Chunab,
        title:"चुनावी प्रचारप्रसारमा बालबालिकाको प्रयोग विरुद्धको सचेतना कार्यक्रम",
        link:"https://www.facebook.com/habs.itahari/posts/pfbid0JqiXjc15V7PGB9weQyFbgNGmKEbfEQrzFFBBPu3tjk9CcFtXDz2LTvzXf87dvR1bl",
        description:"चुनावी प्रचारप्रसारमा बालबालिकाको संलग्नतालाई निरुत्साहित गर्ने उद्देश्यका साथ 'हाम्रो आवाज बाल संजाल'द्वारा तपसिलको मितिमा इटहरी वडा नं.९ भित्रका विभिन्न विद्यालयमा सचेतनामूलक कार्यक्रम सप्पन्न गरिएको छ।"
    },
    {
        id:5,
        image:Anni,
        title:"७ औं वार्षिक उत्सव कार्यक्रम २०७९",
        link:"https://www.facebook.com/habs.itahari/posts/pfbid032y5DkfneUANX4rPHJMbhGCfKGQ5YK5f6BRrQKBbDiResRqiyqnjGViSveJFFvsJdl",
        description:"मिति २०७९/०१/२८ गतेका दिन भोइस अफ चिल्ड्रेन (VOC) सुनसरीमा रहेका बालबालिकाका साथमा विभिन्न मनोरञ्जनात्मक क्रियाकलाप संचालन गरी 'हाम्रो आवाज बाल संजाल' को ७ औं वार्षिक उत्सव कार्यक्रम भव्यताका साथ मनाएका छौँ।"
    },
    {
        id:6,
        image:BaalVela,
        title:"वडा स्तरीय बालभेला २०७९ ",
        link:"https://www.facebook.com/habs.itahari/posts/pfbid02Tm9pZPPzq5srukrNB1jVzbCpHJj3it19PGcN1RZu2QBhc2uPLbH4nc4C8WpgzcRDl",
        description:"अर्थपूर्ण बाल सहभागिताका माध्यमबाट स्थानीय स्तरमा रहेका बालबालिकाका सवालहरु पहिचान गरी स्थानीय तहको आ.व. २०७९/०८० को वार्षिक योजनामा समावेश गर्नका निम्ति हाम्रो आवाज बाल संजालद्वारा इटहरी उप-महानगरपालिकाको सहयोगमा मिति २०७९/०३/११ गतेका दिन इटहरी ९ नं. वडा स्तरीय बालभेला कार्यक्रम सम्पन्न गरिएको छ।"
    },
    {
        id:11,
        image:dashain2080,
        title:'बाल अधिकार सम्बन्धि जनचेतना तथा दशैं विशेष कार्यक्रम',
        link:'https://www.facebook.com/story.php?story_fbid=pfbid0rRPgBPtpygEKEKWHUSvmLFqZ4MdPbjx7euQ4oEbyMckpU1PTY92vouXoVcsTEtLLl&id=100063607870974&mibextid=Nif5oz&paipv=0&eav=Afb2HvMTqSMaaIEHQ2GKcFDDOQn29cNLqyuLvz9Q-9IYxe2S8LAme97tf3-0y5ByyNQ&_rdr',
        description:'मिति २०८०/०७/१० गतेका दिन ९ नं. वडा बाल सञ्जाल (हाम्रो आवाज बाल सञ्जाल) को आयोजना, लक्ष्यदीपको सह-आयोजना तथा वडा कार्यालय इटहरी नौको सहकार्यमा Voice of Children, Itahari को परिवारसँग आयोजना गरिएको "बाल अधिकार सम्बन्धि जनचेतना तथा दशैं विशेष कार्यक्रम" का केही झलकहरु।',
    },
    {
        id:7,
        image:Xyamata,
        title:"क्षमता अभिवृद्धि कार्यक्रम",
        link:"https://www.facebook.com/habs.itahari/posts/pfbid0GnEuWegY1e7a555MUj9a2xdKWJMSzNEDz7LvsAkJXE4kXGc4oSiqXjU4aPsxBVBWl",
        description:"मिति २०७९/०८/१६ र १७ गतेका दिन बाल अधिकार र बालमैत्री स्थानीय शासन विषयमा सदस्यहरुको क्षमता अभिवृद्धि गरी बाल सहभागितालाई अर्थपूर्ण बनाउने उद्देश्यका साथ 'बालअधिकार तथा बालमैत्री स्थानीय शासन सम्बन्धि प्रशिक्षण कार्यक्रम' सम्पन्न गरिएको छ।"
    },
    
    {
        id:8,
        image:Sport,
        title:"खेलकुद कार्यक्रम २०८० ",
        link:"https://facebook.com/habs.itahari/posts/pfbid02HJpSfQkNMsmVi8WJYGGJ6ahsJDD2UQhveFKB3SZW3Qd1nC8xHtKVvwqAgBqu6V3Dl",
        description:"म्रो आवाज बाल सञ्जाल को ८ औँ वार्षिक उत्सवको अवसरमा खेल क्षेत्रमा बालबालिकाको रुचि बढाइ अर्थपूर्ण बालसहभागिता सुनिश्चितताका लागि टेवा पुर्‍याउने उद्देश्यका साथ मिति २०८०/०१/३० गतेका दिन इटहरी ९ नं. वडाका १० वर्षदेखि १८ वर्षसम्मका बालबालिका माझ ९ नं. वडा कार्यालयको सहकार्यमा “Sports Fest” नामक एक दिवसीय खेलकुद कार्यक्रम सम्पन्न गरियो।"
    },
    {
        id:12,
        image:tihar2080,
        title:'देउसी-भैलो कार्यक्रम २०८०',
        link:'https://www.facebook.com/story.php?story_fbid=pfbid0eJWrT2RKzQNV74V1CNAkXrg5kBamCxkGGmt49ujoKNYt623t2aFnHATJFG8FJPQbl&id=100063607870974&mibextid=Nif5oz&paipv=0&eav=AfYvTXzDGWevIEoTcULChqGisOWG4Ts00cy7e3vVpAWewvO866HS8hf4NJ5KBMNl3MY&_rdr',
        description:'Click the button given to see the full video',
    },
];


export default PrgmData;

// start from 13 now
// start from 13 now
// start from 13 now    