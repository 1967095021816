import './index.css';
import HabsLogo from '../../assests/images/HabsPng.png'


const MyLoader = () => {

    return (

        <div className="loader">

            <div className="loaderImg">
                <img src={HabsLogo} 
                alt="HABS Logo" />
            </div>

            <div className="loadingBar-text">
                <h2>
                    HABS team is processing !
                </h2>
                <div className="Loadingbar">
                    <div className="fillBar">

                    </div>
                </div>
            </div>

    </div>

    );
}

export default MyLoader;