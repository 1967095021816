import './index.css';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import MyLoader from '../Loader';
import Contact from '../Contact'
import About from '../About'
import Testimonial from '../Testimonial';
import LandingPage from './LandingPage.js'

const Home = () => {
    const [loading, setLoading] = useState(false);
        useEffect(() => {
            setLoading(true)
                setTimeout(() => {
                    setLoading(false)
                },800)
        },[])
    
    return(
        loading ? <MyLoader /> 
            :  
            <>
            <LandingPage />
            <About />
            <Testimonial />
            <Contact />
            
            </>
          
    );
}

export default Home;