import saksham from '../../assests/images/saksham.jpg'
import amrit from '../../assests/images/amrit.jpg'
import januka from '../../assests/images/januka.jpg'
import shreyashi from '../../assests/images/shreyashi.jpg'
import abishek from '../../assests/images/abishek.jpg'
import pawan from '../../assests/images/pawan.jpg'
import siddhartha from '../../assests/images/siddhartha.jpg'
import jeshis from '../../assests/images/jeshis.jpg'
import purnima from '../../assests/images/purnima.jpg'
import niva from '../../assests/images/niva.jpg'
import kushal from '../../assests/images/kushal.jpg'
import enna from '../../assests/images/enna.jpg'
import simran from '../../assests/images/simran.jpg'
import dikshya from '../../assests/images/dikshya.jpg'
import ashika from '../../assests/images/ashika.jpg'
import kanchan from '../../assests/images/kanchan.jpg'
import shriyasha from '../../assests/images/shriyasha.jpg'
import sonu from '../../assests/images/sonu.jpg';
import prasidhi from '../../assests/images/prasidhi.jpg'

const TenureData = [
    
    {
        id:2,
        tenure:"SecondTenure",
        name:"Amrit Rijal",
        post:"President",
        date:"2075 - 2078",
        image:amrit,
        fblink:'https://www.facebook.com/amrit.rijal.18',
        iglink:'https://www.instagram.com/amrit_rijal/',
        website:'#',
        ytlink:'https://www.youtube.com/@Amrit_Rijal',
        linkedin:'https://www.linkedin.com/in/amrit-rijal?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app'
    },
    {
        id:5,
        tenure:"SecondTenure",
        name:"Siddhartha Katuwal",
        post:"Secretary",
        date:"2075 - 2078",
        image:siddhartha,
        fblink:'#',
        iglink:'#',
        website:'#',
        ytlink:'#',
        linkedin:'#'
    },
    {
        id:3,
        tenure:"SecondTenure",
        name:"Januka Jamarkatel",
        post:"Secretary",
        date:"2075 - 2078",
        image:januka,
        fblink:'https://www.facebook.com/rpjanukasharma',
        iglink:'https://www.instagram.com/januka_sharmaa/',
        website:'#',
        ytlink:'https://www.youtube.com/@janukasharma',
        linkedin:'https://www.linkedin.com/in/januka-jamarkatel?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app'
    },
    {
        id:24,
        tenure:"SecondTenure",
        name:"Pawan Bista",
        post:"Vice Secretary",
        date:"2075 - 2078",
        image:pawan,
        fblink:'#',
        iglink:'#',
        website:'#',
        ytlink:'#',
        linkedin:'#'
    },
    {
        id:19,
        tenure:"SecondTenure",
        name:"Niva Niraula",
        post:"Treasurer",
        date:"2075 - 2078",
        image:niva,
        fblink:'#',
        iglink:'#',
        website:'#',
        ytlink:'#',
        linkedin:'#'
    },
    {
        id:14,
        tenure:"FirstTenure",
        name:"Simran Chaudhary",
        post:"President",
        date:"2072 - 2075",
        image:simran,
        fblink:'#',
        iglink:'#',
        website:'#',
        ytlink:'#',
        linkedin:'#'
    },
    {
        id:11,
        tenure:"FirstTenure",
        name:"Kanchan Poudel",
        post:"Vice President",
        date:"2072 - 2075",
        image:kanchan,
        fblink:'#',
        iglink:'#',
        website:'#',
        ytlink:'#',
        linkedin:'#'
    },
    {
        id:4,
        tenure:"FirstTenure",
        name:"Kushal Nepal",
        post:"Secretary",
        date:"2072 - 2075",
        image:kushal,
        fblink:'#',
        iglink:'#',
        website:'#',
        ytlink:'#',
        linkedin:'#'
      },
        {
            id:16,
            tenure:"FirstTenure",
            name:"Januka Jamarkatel",
            post:"Vice Secretary",
            date:"2072 - 2075",
            image:januka,
            fblink:'https://www.facebook.com/rpjanukasharma',
            iglink:'https://www.instagram.com/januka_sharmaa/',
            website:'#',
            ytlink:'https://www.youtube.com/@janukasharma',
            linkedin:'https://www.linkedin.com/in/januka-jamarkatel?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app'
        },
    {
        id:12,
        tenure:"FirstTenure",
        name:"Dikshya Gurung ",
        post:"Treasurer",
        date:"2072 - 2075",
        image:dikshya,
        fblink:'#',
        iglink:'#',
        website:'#',
        ytlink:'#',
        linkedin:'#'
    },   
    {
        id:20,
        tenure:"FirstTenure",
        name:"Ina Tamang",
        post:"Co-ordinator",
        date:"2072 - 2075",
        image:enna,
        fblink:'#',
        iglink:'#',
        website:'#',
        ytlink:'#',
        linkedin:'#'
    },
        
    

    {
        id:5,
        tenure:"ThirdTenure",
        name:"Sonu Sharma",
        post:"President",
        date:"2078 - 2079",
        image:sonu,
        fblink:'https://www.facebook.com/profile.php?id=100053406362241',
        iglink:'https://www.instagram.com/yours.mr.sharma/',
        website:'#',
        ytlink:'#',
        linkedin:'#'
    },
    {
        id:6,
        tenure:"ThirdTenure",
        name:"Aashika Dahal",
        post:"President",
        date:"2079 - 2080",
        image:ashika,
        fblink:'https://www.facebook.com/aashika.dahal.336',
        iglink:'https://www.instagram.com/aashika_dhl4/',
        website:'#',
        ytlink:'#',
        linkedin:'#'
    },
    {
        id:7,
        tenure:"ThirdTenure",
        name:"Purnima Bhattarai",
        post:"Vice President",
        date:"2078 - 2080",
        image:purnima,
        fblink:'https://www.facebook.com/profile.php?id=100076837406051',
        iglink:'https://www.instagram.com/purnima_bhattarai_/',
        website:'#',
        ytlink:'#',
        linkedin:'#'
    },
    {
        id:8,
        tenure:"ThirdTenure",
        name:"Saksham Khatiwada",
        post:"Secretary",
        date:"2078 - 2080",
        image:saksham,
        fblink:'https://www.facebook.com/skxm0/',
        iglink:'https://www.instagram.com/notsaks_00/',
        website:'www.sakshamk.com/',
        ytlink:'https://www.youtube.com/channel/UCkNEw18tEVGRJic2ZOYrQeA',
        linkedin:'https://www.linkedin.com/in/saksham-khatiwada-509468297?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app'
    },
    {
        id:21,
        tenure:"ThirdTenure",
        name:"Abhishek Dahal",
        post:"Secretary",
        date:"2078 - 2080",
        image:abishek,
        fblink:'#',
        iglink:'#',
        website:'#',
        ytlink:'#',
        linkedin:'#'
    },
    {
        id:27,
        tenure:"ThirdTenure",
        name:"Jeshis Jamarkatel",
        post:"Treasurer",
        date:"2078 - 2080 ",
        image:jeshis,
        fblink:'https://www.facebook.com/jeshisjamarkatel',
        iglink:'https://www.instagram.com/jeshisjamarkatel/',
        website:'#',
        ytlink:'#',
        linkedin:'#'
    },
    {
        id:13,
        tenure:"ThirdTenure",
        name:"Januka Jamarkatel",
        post:"Advisor",
        date:"2078 - 2080",
        image:januka,
        fblink:'https://www.facebook.com/rpjanukasharma',
        iglink:'https://www.instagram.com/januka_sharmaa/',
        website:'#',
        ytlink:'https://www.youtube.com/@janukasharma',
        linkedin:'https://www.linkedin.com/in/januka-jamarkatel?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app'
    },
    {
        id:15,
        tenure:"ThirdTenure",
        name:"Amrit Rijal",
        post:"Advisor",
        date:"2078 - 2080",
        image:amrit,
        fblink:'https://www.facebook.com/amrit.rijal.18',
        iglink:'https://www.instagram.com/amrit_rijal/',
        website:'#',
        ytlink:'https://www.youtube.com/@Amrit_Rijal',
        linkedin:'https://www.linkedin.com/in/amrit-rijal?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app'
    },
    {
        id:18,
        tenure:"FourthTenure",
        name:"Purnima Bhattarai",
        post:"President",
        date:"2080 - Present",
        image:purnima,
        fblink:'https://www.facebook.com/profile.php?id=100076837406051',
        iglink:'https://www.instagram.com/purnima_bhattarai_/',
        website:'#',
        ytlink:'#',
        linkedin:'#'
    },
    {
        id:25,
        tenure:"FourthTenure",
        name:"Shriyasha Thapa",
        post:"Vice President",
        date:"2080 - Present",
        image:shriyasha,
        fblink:'#',
        iglink:'#',
        website:'#',
        ytlink:'#',
        linkedin:'#'
    },
    
    {
        id:9,
        tenure:"FourthTenure",
        name:"Jeshis Jamarkatel",
        post:"Secretary",
        date:"2080 - Present",
        image:jeshis,
        fblink:'https://www.facebook.com/jeshisjamarkatel',
        iglink:'https://www.instagram.com/jeshisjamarkatel/',
        website:'#',
        ytlink:'#',
        linkedin:'#'
    },
    {
        id:22,
        tenure:"FourthTenure",
        name:"Shreyashi Bhattarai",
        post:"Vice Secretary",
        date:"2080 - Present",
        image:shreyashi,
        fblink:'#',
        iglink:'#',
        website:'#',
        ytlink:'#',
        linkedin:'#'
    },
    {
        id:26,
        tenure:"FourthTenure",
        name:"Prasiddhi Bhandari",
        post:"Treasurer",
        date:"2080 - Present",
        image:prasidhi,
        fblink:'#',
        iglink:'#',
        website:'#',
        ytlink:'#',
        linkedin:'#'
    },
    {
        id:8,
        tenure:"FourthTenure",
        name:"Saksham Khatiwada",
        post:"Advisor",
        date:"2080 - Present",
        image:saksham,
        fblink:'https://www.facebook.com/skxm0/',
        iglink:'https://www.instagram.com/notsaks_00/',
        website:'www.sakshamk.com/',
        ytlink:'https://www.youtube.com/channel/UCkNEw18tEVGRJic2ZOYrQeA',
        linkedin:'https://www.linkedin.com/in/saksham-khatiwada-509468297?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app'
    },
   
    {
        id:17,
        tenure:"FourthTenure",
        name:"Amrit Rijal",
        post:"Advisor",
        date:"2080 - Present",
        image:amrit,
        fblink:'https://www.facebook.com/amrit.rijal.18',
        iglink:'https://www.instagram.com/amrit_rijal/',
        website:'#',
        ytlink:'https://www.youtube.com/@Amrit_Rijal',
        linkedin:'https://www.linkedin.com/in/amrit-rijal?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app'
    },
    {
        id:23,
        tenure:"FourthTenure",
        name:"Januka Jamarkatel",
        post:"Advisor",
        date:"2080 - Present",
        image:januka,
        fblink:'https://www.facebook.com/rpjanukasharma',
        iglink:'https://www.instagram.com/januka_sharmaa/',
        website:'#',
        ytlink:'https://www.youtube.com/@janukasharma',
        linkedin:'https://www.linkedin.com/in/januka-jamarkatel?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app'
    },
    {
        id:28,
        tenure:"FourthTenure",
        name:"Aashika Dahal",
        post:"Advisor",
        date:"2080 - Present",
        image:ashika,
        fblink:'https://www.facebook.com/aashika.dahal.336',
        iglink:'https://www.instagram.com/aashika_dhl4/',
        website:'#',
        ytlink:'#',
        linkedin:'#'
    },
    
    
]

export default TenureData;
// start with id = 29
// start with id = 29
// start with id = 29
// start with id = 29
// start with id = 29
// start with id = 29
