import './index.css';
import { NavLink, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { motion } from 'framer-motion';
import HabsPng from '../../assests/images/HabsPng.png'

const NavigationBar = () => {
    
    
    return(
        <>

        <nav className='navigation'>

            <motion.div className='HabsLogo' >
                <motion.Link to='/' style={{display:"flex"}}  >
                    <motion.img
                        src={HabsPng}
                        alt='Hamro Awaj Png image'/>
                        </motion.Link>
            </motion.div>

            <div className='NavHeading'>
            <h1><NavLink  to="/" activeclassname="active">
                <span className='span1'><span>H</span>amro</span> 
                <span className='span2'><span> A</span>waj</span>
                 </NavLink></h1>

            </div>

            <div className='DropDownMenu'>
                <ul>
                    <li>
                        <h3>Explore&nbsp; <FontAwesomeIcon className='FontAwesomeBro' icon={faArrowRight} /> </h3>
                        <ul className='DropDownList'>
                            
                            <li><NavLink to="/"  exact="true" activeclassname="active" >
                                Home
                                </NavLink></li>
                                <li><a href='/#AboutUs'>About Us</a></li>


                            <li><NavLink to="/Programs"  exact="true" activeclassname="active"  >
                                Programs</NavLink></li>

                            <li><NavLink to="/Gallery"  exact="true" activeclassname="active" >
                                Gallery</NavLink></li>

                                <li><a href='/#Testimonials'>Testimonials</a></li>


                            <li><NavLink to="/Tenures"  exact="true" activeclassname="active" >
                                Tenures</NavLink></li>

                            <li><a href='/#ContactUs'>Contact Us</a></li>

                        </ul>
                    </li>
                </ul>
            </div>

        </nav>

        </>
    );
}

export default NavigationBar;