import './index.css';
import Lottie from 'lottie-react';
import HomeLottieData from '../AnimatedSvgs/HomePage.json'
import AnimatedHeading from '../AnimatedHeading';
import { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleDown } from '@fortawesome/free-solid-svg-icons';

const LandingPage = (ContactProp) => {

    const [letterClass, setLetterClass] = useState('text-animate')
    const nameArray = ['H','a','m','r','o',' ','A','w','a','j',' ','B','a','a','l',' ','S','a','n','j','a','l']
    
    
    function HoverAnimation () {
        return setTimeout(() => {
            setLetterClass('text-animate-hover')
          }, 4000)
    }
    useEffect(() => {
        HoverAnimation();
      }, [])
    
      
    const HomepageRef = useRef()
    
    return(
        <>
        
            <div className='HomeMainContainer'>
                        <div className='HomeInfo'>
                                <div className='InFodetails'>
                                <h2 className='HamroH2 H4'>"हाम्रो आवाज हाम्रो अधिकार"</h2>
                                    <h1 className='HamroH1'><AnimatedHeading 
                            letterClass={letterClass}
                            strArray={nameArray}
                            idx={12}
                              /></h1>
                                <h2 className='HamroH2 H3'>Itahari-9, Sunsari</h2>
                                </div>
                                <div className='HomePageBtn'>
                                        <div className='HomeBtn'><a href='#AboutUs'><span className='Text'>Know Us</span><span className='TIcon'><FontAwesomeIcon icon={faAngleDoubleDown} /></span></a></div>


                                </div>
                        </div>

                <div className='HomeLottieSvg'>
                    <div className='LottieHolder'>
                            <Lottie className='LottieHomePage' lottieRef={HomepageRef} 
                            setDirection={-1} animationData={HomeLottieData}
                            />
                    </div>
                </div>
            </div>

        </>
    );
}

export default LandingPage;