import xyamata from '../../assests/images/xyamata.jpg'
import sport from '../../assests/images/sportsEvent.jpg'
import baalvela from '../../assests/images/baalvela.jpg'
import puna from '../../assests/images/punaghathan.jpg'
import deusi from '../../assests/images/deusi2079.jpg'
import dashain from '../../assests/images/dashian.jpg'
import anni from '../../assests/images/anni7.jpg'
import sachena from '../../assests/images/chunab.jpg'
import das1 from '../../assests/images/das1.jpg'
import das2 from '../../assests/images/das2.jpg'
import jj from '../../assests/images/jj.jpg'
import meSaksham from '../../assests/images/me.jpg'
import bela from '../../assests/images/bela.jpg'
import bela1 from '../../assests/images/bela1.jpg'
import bela2 from '../../assests/images/bela2.jpg'
import bela3 from '../../assests/images/bela3.jpg'
import bela4 from '../../assests/images/bela4.jpg'
import bela5 from '../../assests/images/bela5.jpg'
import puna1 from '../../assests/images/puna1.jpg'
import puna2 from '../../assests/images/puna2.jpg'
import puna3 from '../../assests/images/puna3.jpg'
import puna4 from '../../assests/images/puna4.jpg'
import quiz from '../../assests/images/quiz.jpg'
import quiz1 from '../../assests/images/quiz1.jpg'
import punagathan2078 from '../../assests/images/punaghathan.jpg'
import secTransfor from '../../assests/images/secTrans.jpg'
import quiz2 from '../../assests/images/quiz2.jpg'
import das3 from '../../assests/images/das3.jpg'
import das4 from '../../assests/images/das4.jpg'
import das5 from '../../assests/images/das5.jpg'
import das6 from '../../assests/images/das6.jpg'
import das7 from '../../assests/images/das7.jpg'
import deusi2080 from '../../assests/images/deusi2080.jpg'
import tihar2080 from '../../assests/images/tihar2090.jpg'
import deusi2081 from '../../assests/images/deusi2081.jpg'
import deusi2082 from '../../assests/images/deusi2082.jpg'
import deusi2083 from '../../assests/images/deusi2083.jpg'
import baalJana from '../../assests/images/balJana.jpg'

 const ImagesData = [
    {
        id:1,
        imageLink:xyamata,
        type:'Xyamata',
    },
    {
        id:2,
        imageLink:sport,
        type:'sport',
    },
    {
        id:3,
        imageLink:baalvela,
        type:'baalvela',
    },
    {
        id:27,
        imageLink:secTransfor,
        type:'puna',
    },
    {
        id:4,
        imageLink:puna,
        type:'puna',
    },
    {
        id:5,
        imageLink:deusi,
        type:'deusi',
    },
    {
        id:6,
        imageLink:dashain,
        type:'dashain',
    },
    {
        id:7,
        imageLink:anni,
        type:'anni',
    },
    {
        id:29,
        imageLink:puna4,
        type:'puna',
    },
    {
        id:8,
        imageLink:sachena,
        type:'sachena',
    },
    {
        id:9,
        imageLink:das1,
        type:'dashain',
    },
    {
        id:25,
        imageLink:punagathan2078,
        type:'puna',
    },
    {
        id:10,
        imageLink:meSaksham,
        type:'quiz',
    },
    {
        id:11,
        imageLink:jj,
        type:'sachena',
    },
    {
        id:12,
        imageLink:bela,
        type:'baalvela',
    },
    {
        id:13,
        imageLink:puna,
        type:'puna',
    },
    {
        id:14,
        imageLink:das2,
        type:'dashain',
    },
    {
        id:28,
        imageLink:quiz2,
        type:'quiz',
    },
    {
        id:15,
        imageLink:puna1,
        type:'puna',
    },
    {
        id:16,
        imageLink:bela1,
        type:'baalvela',
    },
    {
        id:17,
        imageLink:bela2,
        type:'baalvela',
    },
    {
        id:18,
        imageLink:puna2,
        type:'puna',
    },
    {
        id:19,
        imageLink:bela3,
        type:'baalvela',
    },
    {
        id:20,
        imageLink:quiz,
        type:'quiz',
    },
    {
        id:21,
        imageLink:bela4,
        type:'baalvela',
    },
    {
        id:22,
        imageLink:puna3,
        type:'puna',
    },
    {
        id:23,
        imageLink:quiz1,
        type:'quiz',
    },
    {
        id:24,
        imageLink:bela5,
        type:'baavela',
    },
    {
        id:30,
        imageLink:quiz2,
        type:'quiz',
    },
    {
        id:31,
        imageLink:das3,
        type:'dashain',
    },
    {
        id:32,
        imageLink:bela5,
        type:'baavela',
    },
    {
        id:33,
        imageLink:das4,
        type:'dashain',
    },
    {
        id:34,
        imageLink:baalJana,
        type:'baavela',
    },
    {
        id:35,
        imageLink:deusi2080,
        type:'deusi',
    },
    {
        id:36,
        imageLink:das6,
        type:'dashain',
    },
    {
        id:37,
        imageLink:das5,
        type:'dashain',
    },
    {
        id:38,
        imageLink:deusi2081,
        type:'deusi',
    },
    {
        id:39,
        imageLink:tihar2080,
        type:'deusi',
    },
    {
        id:40,
        imageLink:das7,
        type:'dashain',
    },
    {
        id:42,
        imageLink:deusi2083,
        type:'deusi',
    },
    {
        id:41,
        imageLink:deusi2082,
        type:'deusi',
    },
]
    export default ImagesData;

    //st from 43
    //st from 43
    //st from 43
    //st from 43